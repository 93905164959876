export const status = {
  iban: 'MORE_THAN_TWELVE_MONTHS_WITH_E_COMMERCE_REVENUE',
  psd2StatusV1: 'STORAGE',
  username: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
  bank: 'RABO',
  trackingId: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
};

export const transactionInfo = {
  accounts: [
    {
      accountId: '926109049915710371',
      accountName: 'Account Name',
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000002',
      name: 'Rabobank Nederland B.V.',
      nature: 'account',
      accountNumber: 'Account Number',
      matchState: 'MATCH',
    },
    {
      accountId: '926109049915710372',
      accountName: 'Account Name',
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000003',
      name: 'Rabobank Nederland B.V.',
      nature: 'account',
      accountNumber: 'Account Number',
      matchState: 'COULD_NOT_MATCH',
    },
    {
      accountId: '926109049915710373',
      accountName: 'Account Name',
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000004',
      name: 'Rabobank Nederland B.V.',
      nature: 'account',
      accountNumber: 'Account Number',
      matchState: 'NO_MATCH',
    },
  ],
  bank: 'RABOBANK',
  sub: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
};

export const customerId = '926108919934229314';

export const connectionId = '983437195480144072';
