import { type AxiosPromise } from 'axios';
import api from '../utils/api';
import type { BankCode } from './psd2';

export type DebtorType =
  | 'B2B'
  | 'B2C';

type PreliminaryOffer = {
  duration: number;
  interestCost: number;
  interestRate: number;
  invoiceAmount: number;
  loanAmount: number;
};

type InvoiceData = {
  invoiceId: string;
  invoiceNumber: string;
  invoiceAmount: number;
  invoiceIssueDate: string;
  invoiceDeadlineDate: string;
  debtorKvk: string;
  debtorName: string;
  debtorType: DebtorType;
  preliminaryOffer: PreliminaryOffer;
};

type InvoiceDataResponse = {
  eligible: boolean;
  totalInvoiceAmount: number;
  totalInterestCost: number;
  totalLoanAmount: number;
  message: string;
  invoiceData: InvoiceData;
};

type ContactDetails = {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  campaignIdentifiers: string[];
};

// --------------------------------------------------
// --------------------- INVOICE --------------------
// --------------------------------------------------
export function deleteInvoice(id: string): AxiosPromise {
  const path = `dls-invoice-finance-api/v6/invoice/${id}`;

  return api.remove({ path });
}

type Source = 'OCR' | 'USER';

export type DataSource = {
  invoiceId: Source;
  invoiceAmount: Source;
  invoiceIssueDate: Source;
  invoiceDeadlineDate: Source;
};

type InvoiceDetails = {
  invoiceNumber?: string;
  amount: number;
  issueDate: Date;
  deadlineDate: Date;
  debtorType: DebtorType;
  debtorKvk?: string;
  debtorName: string;
  debtorCompanyId?: string;
  thirtyDaysExtension: boolean;
  dataSource: DataSource;
};

export function updateInvoice(
  invoiceId: string,
  invoice: InvoiceDetails,
): AxiosPromise<InvoiceDataResponse> {
  const path = `dls-invoice-finance-api/v6/invoice/${invoiceId}`;

  const headers = {
    'content-type': 'application/json;charset=UTF-8;',
  };

  return api.put<InvoiceDataResponse>({ path, data: invoice, headers });
}

type CreateInvoiceProps = {
  invoiecDetails: InvoiceDetails;
  file: string;
};

export function createInvoice(invoice: CreateInvoiceProps): AxiosPromise<InvoiceDataResponse> {
  const path = 'dls-invoice-finance-api/v6/invoices';

  const headers = {
    'content-type': 'multipart/form-data',
  };

  return api.post<InvoiceDataResponse>({ path, body: invoice, headers });
}

// --------------------------------------------------
// ------------------- TRANSACTION ------------------
// --------------------------------------------------
type UserConsent = {
  transactionUsageConsent?: boolean;
};

export function consent({ transactionUsageConsent = true }: UserConsent): AxiosPromise {
  const path = 'dls-invoice-finance-api/v6/financials/consent';

  const body = {
    transactionUsageConsent,
  };

  return api.post({ path, body });
}

type RiskEvaluationResponse = {
  eligible: boolean;
  evaluationCode: string;
};

export function riskEvaluation(): AxiosPromise<RiskEvaluationResponse> {
  const path = 'dls-invoice-finance-api/v6/risk-evaluation';

  return api.post<RiskEvaluationResponse>({ path });
}

export type AccountMatch =
  | 'MATCH'
  | 'NO_MATCH'
  | 'COULD_NOT_MATCH'
  | 'NOT_CHECKED';

export type AccountDetails = {
  // accountId: string;
  // name: string;
  // nature: string;
  // accountName: string;
  // accountNumber: string;
  iban: string;
  clientName: string;
  matchState: AccountMatch;
};

export type GetTransactionsResponse = {
  accounts: AccountDetails[];
  sub: string;
  bank: BankCode;
};

type GetTransaction = {
  connectionId: string;
  kvkNumber: string;
};

export function getTransactions({
  connectionId,
  kvkNumber,
}: GetTransaction): AxiosPromise<GetTransactionsResponse> {
  const path = 'dls-invoice-finance-api/v6/transactions/saltedge/fetch-transactions';

  const body = {
    kvkNumber,
    connectionId,
  };

  return api.post<GetTransactionsResponse>({ path, body });
}

// --------------------------------------------------
// ---------------------- OFFER ---------------------
// --------------------------------------------------
type InvoiceBreakdown = {
  invoiceNumber: string;
  invoiceAmount: number;
  invoiceDuration: number;
  interestRate: number;
  interestCost: number;
  loanAmount: number;
  invoiceLoanTerm: number;
  loanExtension: boolean;
};

export type Offer = {
  id: string;
  showOptions: boolean;
  term: string;
  loanAmount: number;
  interestPercentage: number;
  totalInterestCost: number;
  totalInvoiceAmount: number;
  contractCost: number;
  policyAssessmentResultCode: string;
  duration: number;
  invoiceAmount: number;
  invoicesBreakdown: InvoiceBreakdown;
};

type OfferResponse = {
  errorCode: string;
  eligibile: boolean;
  isEligibleForDls: boolean; // only present when eligibile is false
  scoreIsRed: boolean;
  stgEligible: boolean;
  balloonPayments: number;
  leaseEligible: boolean;
  cddClear: boolean;
  offersList: Offer[];
};

export function getOffers(): AxiosPromise<OfferResponse> {
  const path = 'dls-invoice-finance-api/v6/offers';

  return api.get({ path });
}

type FeedbackRequestProps = {
  rating: number;
  referenceSource: string;
  suggestions: string;
};

export function feedback({
  rating,
  referenceSource,
  suggestions,
}: FeedbackRequestProps): AxiosPromise {
  const path = 'dls-invoice-finance-api/v6/feedback';

  const body = {
    rating,
    referenceSource,
    suggestions,
  };

  return api.post({ path, body });
}

type ConfirmOfferProps = {
  offerId: string;
  contactDetails?: ContactDetails;
};

export function confirmOffer({ offerId }: ConfirmOfferProps): AxiosPromise {
  const path = 'dls-invoice-finance-api/v6/confirm-offer';
  const body = { offerId };

  return api.post({ path, body });
}

type SurveyEligibilityResponse = {
  eligible: boolean;
  message?: string;
  errorCode?: string;
};

type SurveyEligibilityProps = {
  businessInOtherCountry: boolean;
  countryName?: string[];
  nlTaxResident: boolean;
  usPerson: boolean;
  usBorn: boolean;
  liveAbroad3years: boolean;
  multipleCompanies: boolean;
};

export function surveyEligibility({
  businessInOtherCountry,
  nlTaxResident,
  usPerson,
  usBorn,
  liveAbroad3years,
  multipleCompanies,
  countryName,
}: SurveyEligibilityProps): AxiosPromise<SurveyEligibilityResponse> {
  const path = 'dls-invoice-finance-api/v6/survey-eligibility';

  const body: SurveyEligibilityProps = {
    businessInOtherCountry,
    nlTaxResident,
    usPerson,
    usBorn,
    liveAbroad3years,
    multipleCompanies,
  };

  if (countryName) {
    body.countryName = countryName;
  }

  const headers = {
    'content-type': 'application/json;charset=UTF-8;',
  };

  return api.post<SurveyEligibilityResponse>({ path, body, headers });
}

// --------------------------------------------------
// ---------------------- MISC ----------------------
// --------------------------------------------------

type UtmTagProps = {
  utm_source: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
};

export function sendAnalytics(tags: UtmTagProps): AxiosPromise {
  const path = 'dls-invoice-finance-api/v6/analytics';

  return api.post({ path, body: tags });
}

export function contactDetails({
  name,
  surname,
  email,
  phoneNumber,
}: ContactDetails): AxiosPromise {
  const path = 'dls-invoice-finance-api/v6/contact-details';

  const body = {
    name,
    surname,
    email,
    phoneNumber,
  };

  return api.post({ path, body });
}

type CaseStateRequester = {
  requesterCompanyKvk: string;
  clientName: string;
  clientSurname: string;
  clientEmail: string;
  clientPhoneNumber: string;
  isNewToTheBank: boolean;
  isEligible: boolean;
};

type CaseStateInvoice = {
  invoiceNumber: string;
  isExtended?: boolean;
  fileKey: string;
  invoiceAmount: number;
  invoiceIssueDate: string;
  invoiceDeadlineDate: string;
  debtorKvk: string;
  debtorName: string;
  debtorType: DebtorType;
  preliminaryOffer: PreliminaryOffer;
};

type Account = {
  bankName: string;
  iban: string;
};

type Transactions = {
  isConsentProvided?: boolean;
  accounts: Account[];
  uploadState: string;
};

export type JourneyBlockState =
  | 'PENDING'
  | 'SUCCESS'
  | 'FAILED';

type JourneyBlockResult = {
  state: JourneyBlockState;
};

export type JourneyBlocks =
  | 'REQUESTER_ELIGIBILITY'
  | 'CONTACT_DETAILS'
  | 'INVOICE_DETAILS_B2B'
  | 'INVOICE_DETAILS_B2C'
  | 'FETCH_SALTEDGE_TRANSACTIONS'
  | 'FETCH_ADS_TRANSACTIONS'
  | 'TRANSACTION_ANALYSIS'
  | 'SCORECARD_RESULT'
  | 'OFFERS_AVAILABLE'
  | 'OFFER_SELECTED';

export type JourneyBlock = { [K in JourneyBlocks]: JourneyBlockResult };

type CaseSteateResponse = {
  offering: unknown;
  origin: string;
  requester: CaseStateRequester;
  invoices: CaseStateInvoice;
  transactions: Transactions;
  journeyBlocks: JourneyBlock;
};

export function getCaseState(): AxiosPromise<CaseSteateResponse> {
  const path = 'dls-invoice-finance-api/v6/case-state';

  return api.get<CaseSteateResponse>({ path });
}

const nonEligibilityReason = [
  'SBI_CODE_INVALID',
  'LEGAL_STRUCTURE_INVALID',
  'FOUNDATION_DATE_CHECK',
] as const;

export type NonEligibilityReason = typeof nonEligibilityReason[number];

export function isNonEligibilityReason(value: unknown): value is NonEligibilityReason {
  return nonEligibilityReason.some((reason) => reason === value);
}

type CompanyCheckResponse = {
  eligible: boolean;
  newToTheBank: boolean;
  nonEligibilityReasons?: string[];
  errorCode?: string;
};

type CompanyCheckProps = {
  kvkNumber: string;
};

export function requesterEligibility({
  kvkNumber,
}: CompanyCheckProps): AxiosPromise<CompanyCheckResponse> {
  const path = 'dls-invoice-finance-api/v6/requester-eligibility';
  const body = { kvkNumber };

  return api.post<CompanyCheckResponse>({ path, body });
}

type CompanySearchResponse = {
  name: string;
  kvkNumber: string;
  id?: string;
  street?: string;
  homeNumber?: string;
  homeNumberAddition?: string;
  pilotBank?: boolean;
  postalCode?: string;
  city?: string;
};

export function companySearch(value = ''): AxiosPromise<CompanySearchResponse[]> {
  const path = `dls-invoice-finance-api/v6/company/search?query=${value}`;

  return api.get({ path });
}

export type CreateSessionResponse = {
  customerId: string;
  expiresAt: string;
  connectUrl: string;
};

type CreateSessionProps = {
  returnUrl: string;
  bankToConnect: BankCode;
  caseId: string;
};

export function createSession({ returnUrl, bankToConnect, caseId }: CreateSessionProps) {
  const path = 'dls-invoice-finance-api/v6/transactions/saltedge/create-session';

  const body = {
    returnUrl,
    caseId,
    bankToConnect,
  };

  return api.post<CreateSessionResponse>({ path, body });
}

export type GetConnectionsResponse = {
  connectionId: string;
};

type GetConnectionsProps = {
  customerId: string;
};

export function getConnectionId({ customerId }: GetConnectionsProps) {
  const path = `dls-invoice-finance-api/v6/transactions/psd2/${customerId}/connections`;

  return api.get<GetConnectionsResponse>({ path });
}
